<template>
  <!-- 健康宣教内容 -->
  <div class="healthy">
    <Scroll
      class="scroll"
      ref="scroll"
    >
      <div v-html="list" />
    </Scroll>
  </div>
</template>

<script>
import Scroll from '@/components/content/scroll/BScroll'
export default {
  name: "healthy",
  data () {
    return {
      list: [`<div style="padding:.4em;max-width:1200px;margin:0 auto;background:#FFF;">
        <div id="topicContent">

          <h2>梳头养生 健康又防病</h2>
          <div
            id="topic_info"
            class="forOther2 topic_info"
          >

            <a
              href="https://www.wang1314.com/user/heakhl4620.html"
              class="ui-link"
            ><img
                style="border-radius: 50%;width:3em;vertical-align: middle;margin-right:.2em;"
                src="/upload/userface/default_face.jpg"
              >蓝风听雨</a>

            &nbsp;收藏于2021-02-20

            &nbsp;&nbsp;转藏1次

          </div>

          <div
            id="postBody0"
            class="post_body"
          >

            <div
              id="body_content"
              style="max-height: none; min-height: 300px; overflow: hidden; height: auto;"
            >
              <article>
                <div>
                  <p>梳头是每个人每天必须要做的事情。梳头发不仅仅是整理发型，更不是例行公事的一种方式，殊不知正确的梳头方法，还能起到养生防病的效果。因此千万不能小看梳头发这个简单的小动作，养生从头开始。下面我们一起来看看梳头养生防病的方法，以及梳头时的注意事项。&gt;&gt;&gt;7种藏在美食背后的惊悚传言 你被坑了吗</p>
                  <p>俗话说：“千过梳头，头不白，梳头养生，健康又防病”。梳头这件小事里竟藏着大学问，赶快来学习！</p>
                  <p><strong>一、梳头养生防病法</strong></p>
                  <p><strong>1、疏通活络</strong></p>
                  <p>头部是人体内外的通路。各种经脉穴位都在头部，头五官和中枢神经也集合于此。所以，经常梳头可以刺激到这些经络和穴位，从而起到畅通经气、疏通血脉、减轻疲劳、缓解头痛、滋养头皮、防止脱发等作用。</p>
                  <p><strong>2、提神健脑</strong></p>
                  <p>我们在生活中常有这种情况，每当疲劳心情烦闷时，洗头或梳头后，就会感到头脑清醒、全身轻松、精神振奋。原因就是在洗头或梳头过程中，可以刺激头皮神经末梢和分布在头部的穴位，通过神经和经络传导，作用于大脑皮层，从而调节经络和神经系统，松弛头部神经，促进头部局部的血液循环，进而起到健脑提神的作用。</p>
                  <p><strong>3、预防感冒</strong></p>
                  <p>头部上有一个穴位位于头部正面，是专门抵御风寒，预防感冒的。所以，在我们梳头发的时候多梳梳正面膀胱经过的部位，就不会轻易感冒了，同时，还可以改善头痛的毛病。</p>
                  <p><strong>4、防止衰老</strong></p>
                  <p>梳头是一种积极的按摩方法。经常梳头，可以疏通血脉，促进大脑和神经的血液供应，有助于降血压，加强新陈代谢。如果经常用梳子对头部穴位和经脉进行按摩和刺激，可预防脑中风，增强脑细胞的营养，延缓大脑衰老。</p>
                  <p>俗话说：“梳头十分钟，预防脑中风”。脑中风是老年人常见病的一种，对付它的最好的方法就是提前加强预防，所以坚持梳头则是一种最简单可行、方便实惠的好方法。</p>
                  <p><strong>5、有助于睡眠</strong></p>
                  <p>在一天的紧致高压力的工作之后，我们可以梳梳头部，这样的话，可以使头部神经放松，有效的减少疲劳，让大脑得到良好的休息。而在睡觉之前，我们梳一梳头发，还能有效的改善我们的睡眠质量，放松身心，优质睡眠。</p>
                  <p><strong>6、有利于心理健康</strong></p>
                  <p>梳理过头发，头发飘逸顺滑，整个人都精神，神采奕奕起来。留给别人一个良好的印象以及带给自己美好愉悦的心情，对心理健康很有利。</p>
                  <p><strong>二、梳头的注意事项</strong></p>
                  <p>梳头发是我们每天都要面对的事情，但是我们真的知道梳头发的正确方法和注意事项吗？下面，我们一起来看一看，到底怎么样才能使我们拥有迷人秀发的同时，还能够健康养生呢？</p>
                  <p><strong>1、巧用梳子</strong></p>
                  <p>一般我们在梳头发的时候，经常会梳不通顺，遇见打结的头发，尤其是长发的女性。那么，当面对打结的头发，首先，我们先选宽齿的梳子将头发梳理开，然后再用按摩梳子来梳头发，这样，就可以有效防止头发因打结而拉断，维护头发的健康状态。</p>
                  <p><strong>2、梳子的选择及卫生</strong></p>
                  <p>日常梳头发的时候，经常会产生静电，我们可以选择牛角梳或者木梳来解决这个问题。其它也可根据实际情况来定。同时，梳头梳子的清洁卫生是很重要的，这一点也常被忽视。</p>
                  <p>梳子上污垢存留时间过久，会发出化学变化，从而引起头皮病。所以梳子一定要经常进行清洗。</p>
                  <p><strong>3、梳头发的顺序</strong></p>
                  <p>梳头发的顺序也是很有讲究的。一般先从头顶顺着头发生长的方向往下慢慢的梳，然后头朝下，半弯腰，从颈后发根的位置往下梳理。不要在头上随便的梳动几下，那样是起不到保健的效果的。</p>
                  <p><strong>4、力度要掌握好</strong></p>
                  <p>在梳头发的时候，不能因为头部有很多的穴位，就拼命大力的用梳子梳头皮，这样容易损坏头部穴位，刺激头皮脂肪的分泌。但也不能太轻，起不到一点重要。我们应该轻重结合，先轻后重，由慢到快，这样能有效刺激到头部的穴位。</p>
                  <p><strong>5、湿发不能梳太久</strong></p>
                  <p>很多人觉得头发在湿润状态下，更好梳理，所以，喜欢选择在洗过头发的时候梳头发。如果你喜欢这么梳头发的话，那就大错特错了！因为头发在湿润状态下，头发的蛋白质结构很松散，发质很脆弱，如果这个时候大力梳头发的话，容易造成发丝的断裂。</p>
                  <p><strong>结语：</strong>今天给大家介绍了梳头养生防病的方法以及梳头时应该注意的事情，大家应该都已经学会了。梳头这件小事，不仅简单易学，还可以养生防病。赶紧动动手指，一起学习梳头的正确方法，并分享给身边的朋友们！</p>
                  <div id="rbox">
                  </div>
                  <div id="rw"></div>
                </div>
              </article>
            </div>

            <div
              id="show_content_bar"
              class="hide-article-box hide-article-pos"
              style="display: none;"
            >
              <div
                id="readAll"
                onclick="readAll()"
                style="line-height:1.5em;margin-top:1em;margin-bottom:.2em;"
              >
                <a
                  href="javascript:readAll();"
                  class="ui-link"
                ><span>&nbsp;阅读全部内容 <i class="fa fa-angle-double-down fa-lg"></i>&nbsp;</span></a>
                <br>
                <span
                  style="font-size:.8em;"
                  class="forOther"
                >点击展开</span>
              </div>
            </div>
          </div>
        </div>
      </div>`, `     <div id="topicContent">

        <h2>健康养生常识 教你学会指甲的养生之道</h2>
        <div
          id="topic_info"
          class="forOther2 topic_info"
        >

          <a
            href="https://www.wang1314.com/user/rzdxl888.html"
            class="ui-link"
          ><img
              style="border-radius: 50%;width:3em;vertical-align: middle;margin-right:.2em;"
              src="/upload/userface/default_face.jpg"
            >黄海居士</a>

          &nbsp;收藏于2021-02-23

          &nbsp;&nbsp;转藏2次

        </div>

        <div
          id="postBody0"
          class="post_body"
        >

          <div
            id="body_content"
            style="max-height: none; min-height: 300px; overflow: hidden; height: auto;"
          >
            <article>
              <div>
                <p>你知道吗？随着现在生活越来科学化的发展，我们可以根据指甲的一些情况来判断身体健康状况，一旦朋友们的身体中出现一些疾病，很多情况下是可以通过指甲来进行一个反应，建议朋友们朋友们通过观察指甲来了解身体的状况，从而达到养生的目的哦。</p>
                <p><strong>指甲养生篇</strong></p>
                <p>首先，请朋友们先来看看自己的指甲，是不是会经常出现的一些情况，相信最常见的就是手指甲内会有一些竖线，简单的说：指甲板上有深深的竖线。</p>
                <p>这和朋友们的神经的情况是有很大的关系的，很有可能是由于神经衰弱导致的，所以这个时候朋友们养生的时候要注意精神上的调养，不能胡思乱想的太多，保持一个宁静的思想！</p>
                <p>既然有竖纹相对应的也是有横的线条的产生的，而且一般横的线条比竖的还要深很多，这个和朋友们的消化系统有关系。</p>
                <p>一般消化系统不太好的人，就容易产生这种横纹，很有可能经常出现在我们有拉肚子的情况，所以有横纹的朋友们，就要注意自己的消化系统了，尽量少吃各种刺激辛辣的食物。</p>
                <p>最后就是要提醒朋友们，还可以通过观察手指甲中，含有月牙白的个数和大小来了解身体的健康情况，一般情况来说手指甲中的月牙白的个数越多，也就意味着我们的的健康指数越高！</p>
                <p><strong>指尖上的6个秘密</strong></p>
                <p>对于爱美的女性来说。在指甲上图上各种各样的颜色图案，不仅看起来很漂亮，而且整个人瞬间感觉时尚了好多。</p>
                <p>除了知道在指甲上图上图案外，我们对指甲又了解多少呢？下面就跟着小编一起看看到底怎么样保养指甲才健康呢？</p>
                <p><strong>指甲和头发的组成物质非常相似</strong></p>
                <p>专家说，指甲是由层层推叠的角蛋白所组成的，跟头发组成物非常相似。我们的身体表面其实也覆盖了一层薄薄的角蛋白，其中最厚的地方是手掌和脚掌。</p>
                <p>那我们的指甲、头发和皮肤到底差在哪里呢？指甲、头发比起皮肤含有更多的半胱氨酸，这导致细胞与细胞的连结更强韧。</p>
                <p>另外，指甲还具有少量的水和脂肪，而皮肤表面则是经常会有角蛋白脱落(指甲并不会如此)。没想到看起来完全两回事的指甲和头发，组成物竟然如此相似，真是太惊奇了！</p>
                <p><strong>指甲在气温高的环境下长得更快</strong></p>
                <p>有关专家表示，人们住在环境相对比较热地方时，指甲成长的速度会变得较快。为什么会出现这样情况呢？</p>
                <p>其实，在指甲中含有大量的维生素D，在阳光的促使下导致指甲长的很快。</p>
                <p><strong>别再去角质啦</strong></p>
                <p>专家说：虽然很多美甲师会磨掉妳的角质层，但那些角质层可是能保护妳避免受细菌感染的重要角色！</p>
                <p>如果过多的修剪角质层，就等于是打开保护身体的屏障，间接让湿气和细菌进入，导致感染、疼痛或肿胀，甚至最后长指甲的时候，导致指甲变形。</p>
                <p><strong>惯用手的指甲长的比较快</strong></p>
                <p>剪指甲的时候，如果妳是右撇子，妳有发现右手的指甲稍微长一点吗？这是因为惯用手比较常活动，造成血液循环较迅速，促进指甲长的比较快的原因。</p>
                <p>想要左手指甲追上右手指甲的速度吗？那现在快点开始甩一甩左手吧！</p>
                <p><strong>前往沙龙可能会损坏妳的指甲</strong></p>
                <p>虽然指甲沙龙目前正当红，不过太过频繁的修指甲，很可能会伤害妳的指甲。连续抛光会让指甲染色有污点，而黏着剂里面的化学物质会层层剥离掉指甲，并使它们变的易碎。</p>
                <p>这需要半年的时间来更换新的指甲，才能得以复原。所以如果妳是一个对美甲很有兴趣的人，建议适当的休息一下会比较好喔！</p>
                <p><strong>吃越健康指甲越漂亮</strong></p>
                <p>我们人的指甲长3毫米大约需要一个月的时间，如果在健康的饮食调理下，六个月就能长出完整的指甲。</p>
                <p>所以很多专家都建议多吃点角蛋白生类的食物，比如说高蛋白、低脂牛奶、维生素C等食物。</p>
                <p><strong>结语：</strong>看了本文，大家是不是觉得原来我们的指甲竟然蕴含了这么多的秘密啊，其实，想要指甲健康并不难，但是大家要在生活中少做一些伤害指甲的事情哦，建议少做美甲，必要为了美丽失了健康哦。</p>
                <div id="rbox">
                </div>
                <div id="rw"></div>
              </div>
            </article>
          </div>

        </div>

      </div>`, `    <div id="topicContent">

        <h2>“二八”健康法则 防病又养生</h2>
        <div
          id="topic_info"
          class="forOther2 topic_info"
        >

          <a
            href="https://www.wang1314.com/user/beyond9088.html"
            class="ui-link"
          ><img
              style="border-radius: 50%;width:3em;vertical-align: middle;margin-right:.2em;"
              src="/upload/userface/default_face.jpg"
            >蓝色天空</a>

          &nbsp;收藏于2021-02-04

          &nbsp;&nbsp;转藏1次

        </div>

        <div
          id="postBody0"
          class="post_body"
        >

          <div
            id="body_content"
            style="max-height: none; min-height: 300px; overflow: hidden; height: auto;"
          >
            <article>
              <div>
                <p><strong>目录：</strong></p>
                <p>第一章：“二八”健康法则 防病又养生</p>
                <p>第二章：常洗冷水澡 防病又养生</p>
                <p>第三章：养生防病 不妨学学“走路操”</p>
                <p>月盈则亏，很多事情做到百分反而并不一定是好事，就像吃饭一样，吃太饱则会出现胃胀等小毛病。生活中有哪些事是做到“八分”就能保健康的呢？防病养生又该如何做呢？</p>
                <p><strong>“二八”健康法则 防病又养生</strong></p>
                <p>着名健康教育专家洪昭光曾提出过一个“八分原则”：饭吃八分饱，菜做八分咸，果蔬占八分，凡事求八分。我曾主编过一本叫《二八健康黄金法则》的书。就像黄金分割点0.618是个神奇的数字一样，2∶8也是一个神奇的比例。经济学上有着名的“二八定律”，指任何一组东西中，最重要的只占其中20%，其余80%尽管是多数，却是次要的。同样，当一个人患病时，20%是由内因决定的，80%则是由外因造成。在这些能由你自己决定的因素中，如果能遵循以下几种“二八健康黄金法则”，就能在很多疾病面前绕道而行。</p>
                <p><strong>二分细八分粗</strong></p>
                <p>“要想不得病，最好吃粗粮”成了近年来的一种流行观点。其实，粗粮和细粮各有好处，就像两个“秘书”一样，共同协助人体消化系统工作。粗粮含有大量不溶性膳食纤维，能改善胃肠微生物菌落，产生的热量也比较低；细粮里的膳食纤维是可溶的，热量较高，但它的蛋白质、氨基酸等含量要比粗粮高。</p>
                <p>之所以提倡大家吃粗粮，是因为我们以往吃的细粮太多，导致了便秘、肥胖、高血脂、动脉硬化、糖尿病等一大堆“富贵病”。而粗粮在防治这些疾病的作用上，远远超过细粮，它甚至能起到防治抑郁症、止喘和抗癌的作用。</p>
                <p>粗粮和细粮最好的搭配是“二分细八分粗”，比如8份黑米或小米，加上2份大米一起煮粥蒸饭；8份玉米面加2份白面一起蒸馒头。如果不喜欢粗粮的口感，可以这样：一是发酵后吃，加点酵母能让粗粮变软；二是打磨粉碎后吃，尤其豆类、麦麸等，磨成浆能让蛋白质的吸收率增加到90%以上；三是加点豆浆或奶，让口味更香甜，还能补钙。</p>
                <p>不过，平时爱吃肉的人刚开始吃粗粮，一定要循序渐进，一下吃太多会让肠道无法适应；吃完粗粮一定要记得多喝水，粗粮中的纤维素需要充足的水分做后盾，才能保证肠道正常工作、帮助消化。豆类最适合治便秘，醋泡黄豆效果显着；小米是健脾和胃的最好食物；玉米、赤小豆、白扁豆中的烟酸能够起到一定的降血糖作用；荞麦、燕麦、大豆中的芦丁等成分可降血脂和软化血管。</p>
                <p>要提醒的是，千万别把“粗粮热”理解为粗粮吃得越多越好，粗粮里的植酸容易和纤维素融合形成沉淀，影响人体对蛋白质和钙、铁等矿物质的吸收，因此吃粗粮的同时最好多补充点海鲜、豆浆、牛奶、鸡蛋、冬瓜、黄瓜等食物。</p>
                <p><strong>二分饥八分饱</strong></p>
                <p>“一不小心就吃撑了”成了很多人的常态。国际病理学研究表明，经常如此不仅会让消化系统负担过重，还会引起内脏器官过早衰老和免疫力下降。总结起来，人会“撑”出以下几种病：肠胃病、胰腺炎、心血管疾病、老年痴呆症、糖尿病和癌症，还会加重慢性呼吸系统疾病。</p>
                <p>最好的饮食原则是：早餐不要吃太饱，五成饱即可；中餐要吃好，但只能吃八成饱；晚饭前最好喝半碗汤，这样可以减少进食量。</p>
                <p>如果你觉得自制力不够，不妨尝试以下办法：一是感觉有点饿就要吃，千万别等非常饿的时候再吃饭，以免吃得过快。二是饭前最好喝碗汤，可以让饭量自动减少1/3；也可以在饭前30～40分钟喝杯果汁或吃点水果，最好是苹果、梨或橙子等好消化的水果。三是每顿饭至少吃20分钟。因为从吃饭开始，大脑要20分钟之后才能接到吃饱的信号，吃得过快，大脑还来不及反应就已经吃撑了。四是一定要细嚼慢咽。多咀嚼能让唾液腺分泌一种叫腮腺激素的物质，它具有抗衰老作用。此外，细嚼慢咽还能让唾液与食物充分混合，起到促进消化、避免餐后出现高血糖、降低胆固醇和血压的作用。</p>
                <p><strong>二分寒八分暖</strong></p>
                <p>你知道吗？把自己裹得像球一样并不是最好的保暖方法。冬天穿衣原则是“二分寒八分暖”，适当受点冻，可以让皮肤绷紧后面积缩小，减少热量损失；寒冷空气还能刺激毛细血管收缩，减少散热；在寒冷的刺激下也能促进血液循环，让人抗寒能力不断增强。相反，总是穿得过多的人比普通人更爱生病，因为厚衣服让身体没法及时散热，导致体温过高，一旦气温变化，非常容易感冒。</p>
                <p>四季都有穿衣重点。春天最讲究下厚上薄，因为人体下半部血液循环比上半部差，很容易遭受风寒侵袭，尤其腿部要充分保暖。夏天防晒要穿深色衣服，颜色越深，紫外线防护性能越高。“秋冻”最适合身体健康的年轻人，穿多了出汗过多容易导致阳气外泄，不利于养生；体质弱的人和老人孩子则要及时增减衣服，避免着凉感冒。冬天要做到“八分暖”，腰腹保暖最重要，因为这里一旦受寒，会影响全身脏腑气血运行。尤其患有心脑血管、胃及十二指肠溃疡、支气管炎、哮喘等疾病的人，还有身体较虚弱的老年人，最好白天戴个腹带，晚上用热水袋暖暖腰腹部，都有助于提高抗病能力。</p>
                <p><strong>二分治八分防</strong></p>
                <p>世界卫生组织一项全球调查曾显示，只有5%的人完全健康，20%的人患有疾病，75%的人都处于亚健康状态。也就是说，全世界有近八成人最需要做的，不是治病，而是防病。对于一个人来说，生活中如果能把八分力气用在防病上，就能避免很多与生活方式有关的慢性病的发生。任何疾病都不是突然到来的，研究显示，脑中风、心肌梗死、癌症、糖尿病等死亡率最高的慢性病，往往要经过10-30年的积累才能发作。也就是说，如果一个人今天突发了脑中风，那么他一定是在10-30年前就已经开始了动脉硬化。在这么长的时间内，如果他能采取有效的防病措施，也许就会改变最后的结局。</p>
                <p>你知道吗？对于人体来说，水是最好的防病“守护神”。它能预防高血压、脑溢血、脑血栓、气喘、过敏、胃溃疡、抑郁在内的多种疾病，国外权威医学研究还发现，摄入水分充足的人，患癌症的概率能降低79%。但多喝水并不意味着乱喝，除了大家熟知的早晨起床最好喝杯水外，医学研究还证明，餐前喝水比餐后喝好，但最好只喝一杯，喝完休息片刻再进食。睡前两小时最好喝杯水，以补充夜晚人体对水分的消耗。此外，人的各个器官都有自己的防病秘诀。胃最喜欢的是饮食规律，不仅定时更要定量。平时要少吃刺激性食物、注意保暖，适当按摩或运动能加强胃肠道蠕动，增强消化功能。肝最怕酒精和大鱼大肉，多吃山楂、黑木耳和蘑菇等有一定的护肝作用。此外，熬夜也是伤肝的重要因素。心血管疾病大多与高胆固醇和高脂肪食物有关，多吃蔬菜、水果和粗粮能起到防病作用。</p>
                <p><strong>二分重八分轻</strong></p>
                <p>有统计学研究表明，人遇到的事情中，只有5%是非常重要或紧迫的，70%～80%的事都不像我们想象的那么重要和紧迫。如果我们用“二分重八分轻”的态度去看待事物，就会放下心中许多烦恼，变得更轻松、更快乐。</p>
                <p>要想将事物真正“放下”，最好做到5种淡泊。首先，淡泊名利。如果成功了就喜笑颜开，失败了就像受到致命打击，名利就成了包袱。换个角度看，即使失败了，但你尝试过，尽力过，也就无悔了，享受过程要好于担忧结果。其次，淡忘年龄。人往往在一年要过完的时候产生恐惧心理，这种情绪会给身体带来消极的暗示。其实，只要你时刻都开开心心地活在当下，不管年龄有多大，都拥有一个快乐的人生。第三，淡忘疾病。把疾病看成一个普通朋友，你越不把它当回事、淡化它，它会越早地逃之夭夭。第四，淡泊情怀。一切喜怒哀乐都淡然若忘，不自扰、不自卑、不沉沦，才是真正的豁然大度。第五，淡然交友。真正好的朋友在于互相之间的取长补短、真诚交流，而不是每天推杯换盏的应酬。“二分重八分轻”教给我们的，是一个人对待事情应有的态度，它决定了人的一生究竟快乐还是不快乐。</p>
                <p><strong>常洗冷水澡 防病又养生</strong></p>
                <p>很早就开始关注冷水澡，担心自己的体质问题，又查阅了很多相关资料，近年尝试四季冷水澡，可喜的是能坚持下来，还颇有心得。</p>
                <p>冷水澡可增强人体对疾病的抵抗能力，通过冷水刺激，血管收缩，部分血液返流内脏，增加内脏氧气与营养的供应，锻炼人体免疫能力，抵御外界寒冷及其他疾病因子的侵袭，预防血管硬化、感冒、支气管炎、扁桃体炎、肺炎等。冷水澡锻炼人的意志和耐受能力，夏天冲凉很痛快，冬天洗冷水澡就要有毅力。</p>
                <p>但是，冷水澡并非对每个人都适合，以下几类人不宜洗冷水澡；高血压、冠心病人；坐骨神经痛、关节炎病人；对冷过敏的人；婴幼儿、老年人及女性等。</p>
                <p>对于大部分人来说，如果洗冷水澡的方法科学，是有利于健康的。我洗冷水澡采取的是循序渐进的方法：</p>
                <p>1、从夏天开始，一直坚持下去，不轻易用热水洗漱。</p>
                <p>2、洗前做热身运动，站稳马步，冲拳三十，再揉搓皮肤，感觉发热为止。</p>
                <p>3、冷水淋湿手脚，人的冷热与手脚关系最密切。</p>
                <p>4、以毛巾浸冷水，湿润四肢，接着是前胸后背。</p>
                <p>5、摩擦身体片刻后，将冷水淋遍全身，一边忍住一口气，一边擦洗。</p>
                <p>6、擦干全身，做洗后热身运动，站稳马步，冲拳三十，不必出汗，发热即可。</p>
                <p>7、穿好衣服，再运气缓缓推掌。</p>
                <p>切忌一开始就浸全身于冷水中，自觉爽快效果就好，时间由自身冷热感觉进行调解。</p>
                <p>冷水澡虽说能强身祛病，但锻炼必须得法，否则会适得其反。是否选择冷水浴，还是综合自己的年龄、体质和环境，慎重为好。</p>
                <p><strong>养生防病 不妨学学“走路操”</strong></p>
                <p>走路恐大家最基本的活动技能，是健康的生活方式，从远古时代人类更多的四肢活动，后柬到身体直立行走，能打猎、劳作等，随着时代的发展上肢活动在减少，现在人们更是以车代步，私家车越来越多，步行作为最好的运动被淡化，甚至逐渐退化，这很危险。</p>
                <p>步行能使身体素质，尤其是有氧耐力得到提高，进而提高了人体各器官、系统的技能、健康水平随之提高，有研究表明快走可显着降低心血管系统疾病的发生，步行对糖尿病、肥胖的防治意义重大，而且步行可以预防老年性痴呆并延缓其发展。步行锻炼能消除不良情绪，使人心情变得愉快，经历充沛。</p>
                <p>走路锻炼时，精神得到放松，腹部肌肉对肠胃起到按摩作用，消化系统功能得提高，尤其适合肠胃不好的人。睡前如果静静低强度走走，还可以起到安神之功效，可以梳理一天的思绪，消除白天的疲惫，使身心得到放松，提高睡眠质量。</p>
                <p>以一次走路锻炼为例，简述一下走的方法：在走之前，可做简单准备活动，主要活动膝、踝关节，开始的速度要慢些，走的时候要放松自然，脑子尽量不要想事，步幅要开阔，两臂配合两腿前后自然摆动，最好采取竞走的摆臂动作，两臂自然下垂，大小臂自然弯曲，以肩关节为轴前后自然摆动，抬头挺胸，上体正直，脚的着地方方法为脚后跟儿迅速过渡到前脚掌。这样的技术动作可使全身大部分肌肉参与活动，特别是胸大肌、上肢肩带大肌肉群也能得到锻炼。另外，呼吸要自然，冷天注意不要张大嘴呼吸，速度不快时，用鼻子呼吸，速度快时可用鼻子和微张嘴呼吸，让空气从鼻和牙缝中进入人体内。</p>
                <p>走路锻炼每星期至少3。刚开始进行走锻炼的人可以先慢些走，然后逐渐增加距离，一段时间适应后，再增力速度，控制一定的距离。对于想康复锻炼的人，一开始采用慢走，放忪走。想减体重的人，吃完饭做完简单家务，就可以慢慢往外走了。</p>
                <p><strong>走路操</strong></p>
                <p><strong>第一节 头颈部活动走</strong></p>
                <p>头颈部做前、后、左右、绕环；做提肩，肩膀经后上提，向前至还原，然后再经前上提，向后至还原。</p>
                <p><strong>第二节胸走</strong></p>
                <p>两手臂经前至胸前曲臂后后振，然后打开两臂经前再后振。注意节奏感。</p>
                <p><strong>第三节振臂走</strong></p>
                <p>两臂经前至上举后振，然后经前放下后摆，也可两臂一上一下后振。</p>
                <p><strong>第四节拍手走</strong></p>
                <p>两手臂伸直经侧体前击掌一次，然后体后击掌一次。</p>
                <p><strong>第五节绕环走</strong></p>
                <p>两手臂伸直以肩关节为轴，大幅度向前绕环，然后再向后绕环。</p>
                <p><strong>第六节转体走</strong></p>
                <p>双臂抬起，上体做扭转动怍，即向左转体同时向前迈左腿，向右转体时向右迈右腿。</p>
                <p><strong>第七节抬腿走</strong></p>
                <p>走5或7步做一次高抬腿动作，即大腿上抬同时两臂弯曲前后摆动配合两腿动作。</p>
                <p><strong>第八节 舒展放忪走</strong></p>
                <p>双臂经体侧缓慢上抬至上举，同时深吸气，然后双臂缓慢向下至体侧，同时呼气。注意动怍与呼吸的配合。</p>
                <p>以上8节操都是在步行中做，要求上肢与下肢协调配合，节奏的快慢，强度的大小根据个人情况而定。</p>
                <p><strong>结语：</strong>水太满则会从茶杯中溢出，很多事情做到“二八”均衡就能让你百病不生，是不是很奇妙哇~（文章原载于《都市健康人》，刊号：2012.03，作者：何裕民；《大众健康》，刊号：2012.03，作者：江泽涵、张志刚，版权归作者所有；转载目的在于传递更多信息，并不代表360常识网赞同其观点和对其真实性负责。如涉及作品内容、版权和其它问题，请尽快与我们联系，我们将在第一时间删除内容）</p>
                <div id="rbox">
                </div>
                <div id="rw"></div>
              </div>
            </article>
          </div>

        </div>

      </div>`, `       <div id="topicContent">

          <h2>冬季健康养生常识</h2>
          <div
            id="topic_info"
            class="forOther2 topic_info"
          >

            <a
              href="https://www.wang1314.com/user/beyond9088.html"
              class="ui-link"
            ><img
                style="border-radius: 50%;width:3em;vertical-align: middle;margin-right:.2em;"
                src="/upload/userface/default_face.jpg"
              >蓝色天空</a>

            &nbsp;收藏于2021-03-01

            &nbsp;&nbsp;转藏2次

          </div>

          <div
            id="postBody0"
            class="post_body"
          >

            <div
              id="body_content"
              style="max-height: none; min-height: 300px; overflow: hidden; height: auto;"
            >
              <article>
                <div>
                  <p>人到冬季身体新陈代谢变慢，抵抗力下降，容易生病，而且特别容易损阳气。冬季养生并不是想象那么复杂，平时留意一些保健的小常识，也可以达到养生的功效。下面小编介绍9个冬季养生的健康小常识，教你少生病的养生之道。</p>
                  <p><strong>避寒就温</strong>冬日气候寒冷，避室应温暖防风，一般宜保持在18℃以上。气温骤降与寒流过境时，老年人与体弱多病者，最好减少外出。若要外出则应做好保暖防护。</p>
                  <p>头背宜暖中医认为，头部与背部为督脉经循行之部位，更是阳中之阳。冬季风寒最易通过头部和背部侵入人体，诱发感冒、肺部感染等疾病。所以要特别重视头和背部的保暖，以免阳气受损。</p>
                  <p><strong>饮食宜温</strong>冬季饮食宜温热，早晨外出时，宜先喝些牛奶、豆浆、红枣粥等以暖和身体。冬季饮食应多选含有优质蛋白质与有防寒保暖作用的食品，如瘦肉、鱼、鸡、蛋类与豆制品等。</p>
                  <p><strong>谨慎洗澡</strong>洗澡能清洁皮肤，促进新陈代谢。但冬季气候寒冷，老年人与体弱多病者，洗澡太勤，不注意保暖，易诱发呼吸道与心脑血管病复发。所以，老年人冬季洗澡一般每周一次即可。</p>
                  <p><strong>热水泡脚</strong>脚是人体之根，素有第二心脏之称。入睡前用40℃～50℃的热水泡泡脚（并配合按摩10多分钟），可加速血液循环、消除疲劳、并有保健益寿之作用。</p>
                  <p><strong>固密心志</strong>冬为闭藏之季，要重视保养精神，固密心志。精神应含而不露，勿使情志过极，以免扰阳。严冬季节，草木凋零，有些老年人会抑郁寡欢，身心处于低落状态。而改变情志的最佳方法就是多活动，在阳光明媚的天气里，最好到室外运动活动，吹拉弹唱、会亲访友，均有益于精神振奋，激起生活热情。</p>
                  <p><strong>傍晚锻炼</strong>经常锻炼可增强体质并提高防寒抗病能力。研究表明，人体化解血栓的能力早晨最低，傍晚最高，如将健身运动安排在傍晚，可使人的免疫能力达到最佳状态。</p>
                  <p><strong>适当进补</strong>冬季气候变化无常，高龄老人与患有疾病的人，要在医生的指导下，有针对性地服用一些具有滋补强壮作用的中药如人参、黄芪、红枣、板栗、核桃仁、黑芝麻等，或对症服些滋补药酒，对增强机体的抗病能力，是有益处的。</p>
                  <div id="rbox">
                  </div>
                  <div id="rw"></div>
                </div>
              </article>
            </div>
          </div>
        </div>`]
    }
  },
  components: {
    Scroll
  },
  mounted () {
    this.list = this.list[this.$route.params.id]
  }
}
</script>

<style lang="less" scoped>
.healthy {
  width: 95vw;
  margin: 0.5rem auto;
}
.scroll {
  height: 99vh;
  overflow: hidden;
}
</style>